import { ethers } from 'ethers';

export const getContract = async (address, abi) => {
  if (!window.ethereum) {
    throw new Error('Ethereum provider not found. Please install MetaMask.');
  }

  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(address, abi, await signer);

  return contract;
};
