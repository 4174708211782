import { useState } from 'react';
import Header from './Components/Header/Header';
import TransactionsList from './Components/TransactionsList/TransactionsList';
import SendTokens from './Components/SendTokens/SendTokens';
import './App.css';

function App() {
  const [lastSend, setLastSend] = useState(null);

  return (
    <div className="app_container">
      <Header />
      <SendTokens setLastSend={setLastSend} />
      <TransactionsList lastSend={lastSend} />
    </div>
  );
}

export default App;
