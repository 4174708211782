// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #61ce70;
}

body {
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;
  font-family: 'roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,iBAAiB;EACjB,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["*,\n*::after,\n*::before {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n:root {\n  --primary: #61ce70;\n}\n\nbody {\n  margin: 0;\n  min-width: 100vw;\n  min-height: 100vh;\n  font-family: 'roboto', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\na {\n  color: black;\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
