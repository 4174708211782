import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import './TransactionsList.css';

const MONTHS = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

const TransactionsList = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchWalletAddress, setSearchWalletAddress] = useState('');

  const fetchTransactions = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallet/stakes`,
      );
      setTransactions(data);
    } catch (error) {
      console.error('Ошибка отображения стейков:', error);
      alert('Ошибка отображения стейков');
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const formatLockTime = (isoString) => {
    const date = new Date(isoString);
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
  };

  const handleSearchChange = (event) =>
    setSearchWalletAddress(event.target.value);

  const filteredTransactions = transactions.filter(({ receiver }) =>
    receiver.toLowerCase().includes(searchWalletAddress.toLowerCase()),
  );

  const sortedTransactions = filteredTransactions.sort(
    (a, b) => new Date(a.unlock_time) - new Date(b.unlock_time),
  );

  return (
    <div className="transactions_container">
      <div className="search_container">
        <input
          type="text"
          value={searchWalletAddress}
          onChange={handleSearchChange}
          placeholder="Поиск по адресу кошелька"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Адрес кошелька</th>
            <th>Кол-во токенов</th>
            <th>Вывод из стейкинга</th>
            <th className="button_column">Транзакция</th>
          </tr>
        </thead>
        <tbody>
          {sortedTransactions.map((tx, index) => (
            <tr key={tx.hash || index}>
              <td>{index + 1}</td>
              <td>
                <a
                  href={`https://bscscan.com/address/${tx.wallet_address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {tx.receiver}
                </a>
              </td>
              <td>{tx.amount}</td>
              <td>{formatLockTime(tx.unlock_time)}</td>
              <td>
                <a
                  href={`https://bscscan.com/tx/${tx.hash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  Ссылка на транзакцию
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsList;
