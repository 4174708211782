// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}

@media (max-width: 760px) {
  .app_container {
    width: 100%;

    margin: 0 auto;
    padding: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;;IAEX,cAAc;IACd,aAAa;EACf;AACF","sourcesContent":[".app_container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 30px 0;\n}\n\n@media (max-width: 760px) {\n  .app_container {\n    width: 100%;\n\n    margin: 0 auto;\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
